import './style.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Rollbar from 'rollbar';

// import InstantSearch from 'vue-instantsearch/vue3/es';

import App from './App.vue'
import router from './router'

import "@/plugins/axios";

const app = createApp(App);

app.use(createPinia());
// app.use(InstantSearch);
app.use(router);

if (import.meta.env.VITE_ROLLBAR_ENABLED == 'true') {
  const rollbar = new Rollbar({
    accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
    captureUncaught: import.meta.env.VITE_ROLLBAR_ENABLED == 'true',
    captureUnhandledRejections: import.meta.env.VITE_ROLLBAR_ENABLED == 'true',
    payload: {
      environment: process.env.NODE_ENV || 'production' || 'development',
    },
  });
  
  app.config.errorHandler = (err, vm, info) => {
    rollbar.error(err);
    throw err; // rethrow
  };
  app.config.globalProperties.$rollbar = rollbar;
}

app.mount('#app');
